
import { Vue, Component, Prop } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import Ace from "@/components/helpers/ace.vue";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import MetaData from "./product-class-param-item.json";
import InlineFileUploader from "@/components/inline-file-uploader.vue";
@Component({
  components: { InlineFileUploader, Ace },
})
export default class editorSelectorExtends extends Vue {
  @Prop() param!: any;
  visible: boolean = false;
  json: string = "";
  editors = [
    {
      name: "string",
      caption: "Текстовое поле",
    },
    {
      name: "number",
      caption: "Числовое поле",
      min: 0,
      max: 100,
    },
    {
      name: "boolean",
      caption: "Чекбокс",
    },
    {
      name: "enum",
      caption: "Выбор одного элемента из списка",
      mode: "radio",
      labelKeyName: "caption",
      valueKeyName: "value",
      items: [
        {
          caption: "",
          value: 0,
        },
      ],
    },
    {
      name: "color",
      caption: "Цвет",
      mode: "single",
      colorKeyName: "color",
      labelKeyName: "caption",
      valueKeyName: "value",
      items: [
        {
          caption: "Белый",
          color: "#fff",
          value: "white",
        },
      ],
    },
    {
      name: "array",
      caption: "Выбор нескольких элементов из списка",
      valueKeyName: "value",
      labelKeyName: "caption",
      items: [
        {
          caption: "",
          value: 0,
        },
      ],
    },
  ];

  dataSource: ObjectDataSource = new ObjectDataSource({
    id: this.param.id,
    className: "productClassParam",
    config: {},
    model: this.param,
  });

  paramItemDataSource: ListDataSource = new ListDataSource({
    className: "productclassparamitem",
    config: {},
  });

  updateColor(id: number, event: string) {
    const items = this.paramItemDataSource.getMetaDataByName("value").items;
    const item = items.find((i: any) => i.color == event);
    if (!item) return;
    this.paramItemDataSource.updateField(id, [
      {
        fieldName: "value",
        fieldValue: item.color,
      },
      {
        fieldName: "caption",
        fieldValue: item.caption,
      },
    ]);
    this.$message("Значение успешно сохранено");
  }

  get showItems() {
    return !["number", "string", "checkbox"].includes(
      this.dataSource.model.editor
    );
  }

  itemTypes = [
    {
      name: "enum",
      caption: "Выбор 1 элемента из списка",
    },
    {
      name: "array",
      caption: "Выбор нескольких элементов из списка",
    },
    {
      name: "color",
      caption: "Выбор цвета",
    },
    {
      name: "number",
      caption: "Число",
    },
    {
      name: "string",
      caption: "Строка",
    },
    {
      name: "checkbox",
      caption: "Чекбокс",
    },
  ];

  async updateEditorData(event: any) {
    const item = this.itemTypes.find((i: any) => i.name == event);
    if (!item) return;
    this.dataSource.model.editor = item.name;
    this.dataSource.model.editorCaption = item.caption;
    await this.dataSource.update();
  }
  $message: any;

  get editor() {
    const item = this.itemTypes.find(
      (i: any) => i.name == this.dataSource.model.editor
    );
    return item;
  }

  isCorrectJson = (json: string) => {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  };

  async submit() {
    this.visible = false;
  }

  created() {
    this.json = this.dataSource.model.editor;
    this.paramItemDataSource.items = this.dataSource.model.items;
    this.paramItemDataSource.metadata = MetaData;
  }
}
