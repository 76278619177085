
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ListDataSource} from "@/data/List/ListDataSource";
import draggable from "vuedraggable";
import EditorSelector from "@/pages/manage/productclasses/components/editor-selector.vue";
@Component({
  components:{EditorSelector, draggable}
})
export default class productClassParams extends Vue {
  @Prop() metaData!:any
  @Prop() model!:any;


  $message: any;

  dataSource:ListDataSource = new ListDataSource({
    className:"ProductClassParam",
    config:{}
  })



  async add(addToEnd:boolean, index:number){
    await this.dataSource.add({
      caption:"Новый параметр " + (+this.dataSource.items.length + 1),
      productClassParamGroupId:this.model.id,
      productClassId: +this.$route.params.id
    }, addToEnd,index)
    await this.dataSource.changePriority();
    this.$message("Успешно добавлено")
  }

  async remove(param:any){
    await this.dataSource.remove(param.id);
    this.$message("Успешно удалено")
  }

  async created(){
    this.dataSource.items = this.model.params.sort((n:any,p:any)=>n.priority - p.priority);
    this.dataSource.metadata = this.metaData;
  }
}
