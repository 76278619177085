
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import ProductClassEditor from "@/pages/manage/productclasses/components/product-class-editor.vue";
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";

@Component({
  components: {ProductClassEditor}
})
export default class Home extends Vue {
  loaded:boolean = false;
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "productclass",
  });

  productClassParamGroupDataSource:ListDataSource = new ListDataSource({
    className:"ProductClassParamGroup",
    config:{
      orderFieldName:"Priority",
      orderFieldDirection:"ASC",
      filter:JSON.stringify([
          new Filter("productClassId", +this.$route.params.id)
      ])
    }
  })

  get caption(){
    return this.dataSource.model?.caption
  }

  async mounted(){
    await this.productClassParamGroupDataSource.get();
    await this.productClassParamGroupDataSource.getMetaData();
    this.loaded = true;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Классы продукта",
        to: "/manage/productclasses/",
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }
}
